.App {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    padding: 2rem;
    box-sizing: border-box;

    line-height: 1.4;
}

.AppContainer {
    max-width: 800px;
    padding-bottom: 2rem;
}

.AuthTokenArea {
    display: flex;
    flex-direction: row;
    margin: 1rem 0;
    column-gap: 1rem;
}
.AuthTokenArea .HighlightBanner {
    background: midnightblue;
    border-radius: 0.5rem;
    align-self: stretch;
    width: 0.5rem;
}
.AuthTokenArea .AreaContent {
    padding: 0.5rem 0;
}

.AuthTokenArea .AreaContent p:first-child {
    margin-top: 0;
}