@import 'helvetica_now';
@import 'colours';

body {
  margin: 0;
  font-family: 'HelveticaNow', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $darkgrey;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 2.25rem;
  font-weight: 600;
  font-stretch: condensed;
  line-height: 1.28;
}