@import 'helvetica_now';
@import 'colours';

.uppy-Root {
  color: $darkgrey;
  font-family: 'HelveticaNow', Helvetica, Arial, sans-serif;
}

.uppy-Dashboard-inner {
  background-color: $offwhite;
}

.uppy-DashboardContent-back,
.uppy-DashboardContent-save,
.uppy-DashboardContent-addMore,
.uppy-Dashboard-browse {
  color: $blue;

  &:hover {
    color: $blue--darker;
  }
}

.uppy-Dashboard-AddFiles-title {
  color: $darkgrey;
}

.uppy-c-btn-primary {
  background-color: $blue;

  &:hover {
    color: $blue--darker;
  }
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background-color: $green;

  &:hover {
    background-color: $green--darker;
  }
}

.uppy-Dashboard-Item-action--remove {
  color: $blue;

  &:hover {
    color: $blue--darker;
  }
}

.uppy-StatusBar-actionBtn--retry {
  background-color: $red;

  &:hover {
    background-color: $red--darker;
  }
}

.uppy-StatusBar.is-error {
  .uppy-StatusBar-progress {
    background-color: $red;
  }

  .uppy-StatusBar-statusIndicator {
    color: $red;
  }
}

.uppy-StatusBar-statusIndicator {
  color: $darkgrey;
}

a.uppy-Dashboard-poweredBy {
  display: none;
}

.help {
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  column-gap: .5rem;
  font-size: .875rem;

  img {
    color: $blue;
    width: 24px;
    height: 24px;
  }

  a:visited {
    color: $blue--darker;
  }
}

.warn {
  font-size: .875rem;
  margin-bottom: 1rem;

  strong {
    color: $orange--darker;
  }
}