/**
 * @license
 * MyFonts Webfont Build ID 3183969,
    2016-03-09T09:39:13-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3183969
 * Webfonts copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted &#x00A9; 1988 - 2006 Linotype GmbH,
    www.linotype.com. All rights reserved. This software is the property of Linotype GmbH,
    and may not be repro
 *
 * © 2016 MyFonts Inc
*/

// Helvetica Now Display

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_C_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_C_0.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_B_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_B_0.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_1_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_1_0.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_8_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_8_0.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_5_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_5_0.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_2_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_2_0.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_9_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_9_0.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-stretch: condensed;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_A_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_A_0.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-stretch: condensed;
  font-display: swap;
}

// Helvetica Now Text

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_21_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_21_0.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_28_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_28_0.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_25_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_25_0.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_22_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_22_0.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_29_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_29_0.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_2A_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_2A_0.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

// Helvetica Now Micro

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_16_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_16_0.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-stretch: expanded;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_17_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_17_0.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: expanded;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/HVMicro500.woff2') format('woff2'),
    url('/style/fonts/HVMicro500.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: expanded;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/HVMicro500It.woff2') format('woff2'),
    url('/style/fonts/HVMicro500It.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: expanded;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_15_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_15_0.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: expanded;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_1B_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_1B_0.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-stretch: expanded;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src:
    url('/style/fonts/3AF163_1C_0.woff2') format('woff2'),
    url('/style/fonts/3AF163_1C_0.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-stretch: expanded;
  font-display: swap;
}