$white: #fff;
$lightblue: #90c6e7;
$blue: #00789A;
$darkgrey: #455556;
$palegrey: #b5aea7;
$dimgrey: #918b86;
$lightgrey: #e4e6e8;
$mediumgrey: #687576;
$green: #15A96A;
$orange: #f57e20;
$paleyellow: #ede797;
$purple: #a53f97;
$red: #ef4135;
$yellow: #f5cc49;
$background: #f0efed;
$black: #4a4a4a;
// Bg Colors
$celsius: #f0efed;
$offwhite: #f9f9fb;
$darkblue--faded: #005c75;
// Faded Colors
$red--faded: #fee8de;
$orange--faded: #feeddb;
$yellow--faded: #fefcf2;
$green--faded: #edf5f2;
$blue--faded: #f2f7f9;
// Darkened Colours
$blue--darker: #245a6f;
$green--darker: #0b814f;
$red--darker: #be2a20;
$purple--darker: #772c6d;
$orange--darker: #b95e17;
$palegrey--darker: #8c8379;
$lightblue--darker: #548caf;